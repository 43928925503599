export const environment = {
  production: true,
  connection: {
    name: 'Test',
    host: 'https://softline-test.inn-traun.lagerhaus.at',
    port: 443,
    basePath: '/restapi/api'
  },
  theme: 'test',
  androidUpdateRepository: ''
};
